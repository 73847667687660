import { useState } from "react";
import { user_cellphone_form_text, user_types_dropdown_label_text, create_modal_submit_text, create_modal_text, dropdown_default_text, modal_cancel_text, user_classname_text, user_email_filter_text, user_name_filter_text, user_role_filter_text, user_types_dropdown_text, user_full_name_form_text, user_family_name_form_text, Password, confirmation_modal_submit_text } from "../../constant";
import {userTypesToCreate} from "../../constant/constants.js";


const ConfirmationModal = ({ description,isVisible, onSubmit, onCancel}) => {


    return (
        <div id="modal" className="my-modal-container" style={isVisible?{display:"block"}:{display:"none"}}>
            <div className="modal-content">
                <div className="">
            
                <form className="form theme-form">
                    <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <p>{description}</p>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="card-footer text-end">
                        <button className="btn btn-primary me-2" type="button" onClick={onSubmit} >{confirmation_modal_submit_text}</button>
                        <button className="btn btn-light" type="reset" onClick={onCancel} >{modal_cancel_text}</button>
                    </div>
                </form>
                </div>
            </div>
            </div>
    )
}

export default ConfirmationModal;