import React, { useState, useEffect } from 'react';
import './toggle_button.scss';

const XToggleButton = ({checked, onClick}) => {
  

  return (
    <div className='toggle-btn-container'>
      <div className={`toggle-btn-bar`}>
        <button onClick={onClick} className={`toggle-btn${checked?'-checked':''}`}/>
      </div>
    </div>
  );
};

export default XToggleButton;