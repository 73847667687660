import { useLocation } from "react-router-dom";
import useCrud from "../../hooks/use_crud";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../components/my-components/loading_spinner";
import { order_details_title_text, order_statuses_dropdown_texts, order_title_text, order_type_classname_text, order_type_description_field_text, order_type_extrafields_field_text, order_type_name_field_text } from "../../constant";
import DataTable from 'react-data-table-component'
import { Breadcrumb } from "reactstrap";
import { orderStatuses } from "../../constant/constants";


function OrderDetails({}){

    const [loading,setLoading] = useState(false)
    const location = useLocation();
    const [order, setOrder] = useState(null);
    const {findById} = useCrud({url: "orders", setLoading: setLoading, firstLoad:false});
    const [extraFields, setExtraFields] = useState([])
    const getOrder = async (id) => {
        let orderAux = await findById(id)
        setOrder(orderAux)
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        getOrder(searchParams.get('id'));
    }, [location]);

    useEffect(()=>{
        if(order){
            order.extraFields.map(field => {
                order.orderType.extrafields.filter(extraField => {
                    if(extraField.id == field.fieldId){
                        field.name = extraField.name
                        field.type = extraField.type
                    }
                })
            })

            const result = [];
        
            for (let i = 0; i < order.extraFields.length; i += 3) {
                const chunk = order.extraFields.slice(i, i + 3);
                result.push(chunk);
            }

            setExtraFields(result)
        }
    },[order])

    const columns = [
        {name: 'Estado',selector: (row) =>order_statuses_dropdown_texts[row.status],sortable: true,center:true,},
        {name: 'Usuario',selector: (row) =>row.user.name,sortable: true,center:true,},
        {name: 'Fecha',selector: (row) =>(new Date(row.createdAt)).toLocaleDateString(),sortable: true,center:true,},
        {name: 'Razón',selector: (row) =>row.reason,sortable: true,center:true,},
    ];

    return (
        <div className="card">
            <Breadcrumb parent={order_title_text} title={order_details_title_text + (order ? order.id : '') }/>
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        <label>{'Tipo'}</label>
                        <input className="form-control" type="text" disabled style={{marginLeft: 10}} value={order ? order.orderType.name : ''} />
                    </div>
                    <div className="col">
                        <label>{'Estado'}</label>
                        <input className="form-control" type="text" disabled style={{marginLeft: 10}} value={order ? order_statuses_dropdown_texts[order.status] : ''} />
                    </div>
                </div>
                {
                    order && order.driver &&
                    <div className="row">
                        <div className="col">
                            <label>{'Conductor'}</label>
                            <input className="form-control" type="text" disabled style={{marginLeft: 10}} value={order ? order.driver.name : ''} />
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col">
                        <label>{'Cliente'}</label>
                        <input className="form-control" type="text" disabled style={{marginLeft: 10}} value={order ? order.user.name : ''} />
                    </div>
                    <div className="col">
                        <label>{'Precio'}</label>
                        <input className="form-control" type="text" disabled style={{marginLeft: 10}} value={order ? order.prize : ''} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>{'Estado'}</label>
                        <input className="form-control" type="text" disabled style={{marginLeft: 10}} value={order ? order.address.colony?.municipality?.state?.name : ''} />
                    </div>
                    <div className="col">
                        <label>{'Municipio'}</label>
                        <input className="form-control" type="text" disabled style={{marginLeft: 10}} value={order ? order.address.colony?.municipality?.name : ''} />
                    </div>
                    <div className="col">
                        <label>{'Colonia'}</label>
                        <input className="form-control" type="text" disabled style={{marginLeft: 10}} value={order ? order.address.colony?.name : ''} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>{'Dirección'}</label>
                        <input className="form-control" type="text" disabled style={{marginLeft: 10}} value={order ? order.address.street + ' #' + order.address.insideNumber + ' e/ ' + order.address.betweenStreets : ''} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>{'Referencias'}</label>
                        <input className="form-control" type="text" disabled style={{marginLeft: 10}} value={order ? order.address.references : ''} />
                    </div>
                </div>
                {
                    extraFields.map( chunk => 
                        <div className="row">
                            {
                                chunk.map( field => 
                                        <div className="col">
                                            <label>{field.name}</label>
                                            <input className="form-control" type="text" disabled style={{marginLeft: 10}} value={field.value} />
                                        </div>
                                )
                            }
                        </div>
                    )
                }
                <br/>
                <h2>Historial de cambios</h2>
                <br/>
                <DataTable
                    pagination 
                    paginationServer
                    paginationComponentOptions ={{
                        noRowsPerPage:true
                    }}
                    paginationTotalRows={order?order.changes.length:0} 
                    striped={true}
                    center={true}
                    columns={columns}
                    data={order?order.changes:[]}
                />
            </div>
            <LoadingSpinner isVisible={loading} />
        </div>
    );

}

export default OrderDetails;