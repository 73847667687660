import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component'
import useCrud from '../../hooks/use_crud';
import "react-toastify/dist/ReactToastify.css";
import "./driver_orders_view.css";
import LoadingSpinner from '../../components/my-components/loading_spinner';
import IconButton from '../../components/my-components/my_icon_button';
import { delete_modal_text, delete_text, dropdown_default_text, filters_text, order_assign_button_text, order_state_filter_text, order_statuses_dropdown_texts, order_title_text, order_type_filter_text, role_classname_text } from '../../constant';
import { orderStatuses } from '../../constant/constants';
import AssignDriverModal from './acccept_order_modal';
import OrderCard from '../../components/my-components/order_card';
import Tab from '../../components/my-components/tab';
import RejectOrderModal from './reject_order_modal';


const DriverOrdersView = () =>{

    const [loading,setLoading] = useState(false)
    const {list: onCourseOrders,reload: reloadOnCourse} = useCrud({url: "orders",filters:{status:"ONCOURSE"},pagination:{size:30,page:0}, setLoading: setLoading, firstLoad:true, findUrl:'find-driver-orders/'})
    const {list: assignedOrders, reload:reloadAssigned} = useCrud({url: "orders",filters:{status:"ASSIGNED"},pagination:{size:30,page:0}, setLoading: setLoading, firstLoad:true, findUrl:'find-driver-orders/'})
    const {list: acceptedOrders, reload: reloadAccepted} = useCrud({url: "orders",filters:{status:"ACCEPTED"},pagination:{size:30,page:0}, setLoading: setLoading, firstLoad:true, findUrl:'find-driver-orders/'})
    const [isAssignedTabSelected, setIsAssignedTabSelected] = useState(true)
    const [isRejectOrderModalOpen, setIsRejectOrderModalOpen] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [filters, setFilters] = useState({});


    useEffect(()=>{
        let filtersAux = filters

        setFilters(filtersAux)
    },[])


    const handlePageChange = page => {
        //find(filters,{size:10,page:page-1});
      };


    const handlePerRowsChange = async (newPerPage, page) => {
        //find(filters,{size:newPerPage,page:page});

      };
    
    const reload = () => {
        reloadOnCourse();
        reloadAssigned();
        reloadAccepted();
    }

    const getOrderButtons = (order) => {

        if(order.status == 'ACCEPTED')
            return [<Button className='order-accept-button' color='green' onClick={()=>{startOrder(order.id)}}>Iniciar</Button>]
        if(order.status == 'ASSIGNED')
            return [<Button className='order-accept-button' color='green' onClick={()=>{acceptOrder(order.id)}}>Aceptar</Button>]
        if(order.status == 'ONCOURSE')
            return [
                <Button className='order-end-button' color='brown' onClick={()=>{completeOrder(order.id)}}>Terminar</Button>,
                <Button className='order-cancel-button' color='brown' onClick={()=>{
                    setSelectedOrder(order)
                    setIsRejectOrderModalOpen(true)
                }}>Cancelar</Button>
            ]

    }

    const changeOrderStatus = async (orderId, status, reason)=>{

        setLoading(true)
    
        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}orders/${orderId}/change-status/${status}?reason=${reason}`,
                {
                method:"PUT",
                headers:{
                    "Content-Type":"application/json",
                    "Authorization":`Bearer ${localStorage.getItem("userToken")}`
                }
                }
            )
                
        setLoading(false)
    }

    const acceptOrder = async (orderId) => {

        await changeOrderStatus(orderId, 'ACCEPTED', '')
        reloadAssigned()
        reloadAccepted();

    }

    const startOrder = async (orderId) => {

        await changeOrderStatus(orderId, 'ONCOURSE', '')
        reloadAccepted();
        reloadOnCourse();

    }

    const completeOrder = async (orderId) => {

        await changeOrderStatus(orderId, 'COMPLETED', '')
        reloadOnCourse();

    }

    const rejectOrder = async (orderId, reason) => {

        await changeOrderStatus(orderId, 'PENDING', reason)
        reloadOnCourse();

    }

    return (
        <div>
            <br/>
            <div className='tab-container'>
                <Tab
                selected={true}
                text={'En Curso'}
                onClick={()=>{}}
                />
            </div>
            <br/>
            <div>
                {
                    onCourseOrders.map(order => <OrderCard order={order} buttons={getOrderButtons(order)}/>)
                }
            </div>
            <div className='tab-container'>
                <Tab
                selected={!isAssignedTabSelected}
                text={'Aceptadas'}
                onClick={()=>{setIsAssignedTabSelected(false)}}
                />
                <Tab
                selected={isAssignedTabSelected}
                text={'Asignadas'}
                onClick={()=>{setIsAssignedTabSelected(true)}}
                />
            </div>
            <br/>
            <div>
            <div>
                {
                    isAssignedTabSelected?
                    assignedOrders.map(order => <OrderCard order={order} buttons={getOrderButtons(order)}/>)
                    :acceptedOrders.map(order => <OrderCard order={order} buttons={getOrderButtons(order)}/>)
                }
            </div>
            </div>
            <RejectOrderModal 
                isVisible={isRejectOrderModalOpen}
                onSubmit={(reason)=>{
                    rejectOrder(selectedOrder.id,reason)
                    setIsRejectOrderModalOpen(false)
                }}
                onCancel={()=>setIsRejectOrderModalOpen(false)}
             />
            <LoadingSpinner isVisible={loading} />
            <ToastContainer />
        </div>
      
      )
}

export default DriverOrdersView;