
export const validateForm = (model, validators) => {
   let validationResults = {};

    for (const key in validators) {
        if (validators[key]) {
            const validationResult = validators[key](model[key])
            if(typeof validationResult === 'string')
                validationResults[key] = validationResult
        }
    }

    return {
        isValid: Object.keys(validationResults).length == 0,
        ...validationResults
    };
};

export const showFormErrors = (validationResults, toast) => {

    for (const key in validationResults) {
       
        if(typeof validationResults[key] === 'string')
            toast.error(validationResults[key])
  
    }

};

export const andValidators = (validators) => {

    return (value) =>  {
        let validatorsResult = ''

        for (const validator of validators) {
            const result = validator(value)
            if(typeof result === 'string'){
                validatorsResult += result
            }
        }
        return validatorsResult === ''? true : validatorsResult;
    }
}

export const orValidators = (validators) => {
    
    return (value) =>  {
        let validatorsResult = ''

        for (const validator of validators) {
            const result = validator(value)
            if(typeof result !== 'string'){
                return true
            }
            else{
                validatorsResult += result + '\n'
            }
        }
        return validatorsResult;
    }
}

export const notRequiredValidator = (value) => {
    
    return typeof value === 'string' && value.trim().length == 0 ? true : 'Valor llenado';
}

export const requiredValidator = (fieldName) => {
    return (value) => (typeof value === 'string' && value.trim().length > 0) || (typeof value !== 'string' && value !== null && value !== undefined) ? true : `El campo: ${fieldName} es requerido`;
}

export const requiredDropdownValidator = (fieldName) => {
    return (value) => value != -1 ? true : `El campo: ${fieldName} es requerido`;
}

export const checklistRequiredValidator = (fieldName) => {
    return (value) => value.length > 0 ? true : `Debe seleccionar al menos un ${fieldName}`;
}

export const emailValidator = (value) => {
     const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
     return emailPattern.test(value)? true : 'El correo debe tener el formato: usuario@dominio';
}

export const passwordValidator = (value) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>\/?]).{8,}$/;
    return passwordPattern.test(value) ? true : "La contraseña debe ontener mayúsculas, minúsculas, números y caracteres especiales con un mínimo de 8 caracteres";
};

export const phoneValidator = (value) => {
    const phonePattern = /^52\s[0-9]{3}\s[0-9]{3}\s[0-9]{4}$/;
    return phonePattern.test(value)? true : 'El teléfono debe ser de México (52 XXX_XXX_XXXX)';
};

