import React, { useState, useEffect } from "react";
import logo from "../assets/images/endless-logo.png";
import man from "../assets/images/user/1.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Login, LOGIN, YourName, Password, RememberMe, session_expired, RepeatPassword, UpdatePasswordButton, RecoverPasswordTittle, RecoverPasswordSubtittle } from "../constant";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactReduxContext } from "react-redux";
import LoadingSpinner from "../components/my-components/loading_spinner";

const RecoverPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsloading] = useState(false)
    const [token, setToken] = useState('')
    const [password, setPassword] = useState('')
    const [repeatedPassword, setRepeatedPassword] = useState('')
  
  
  
    const updatePassword = async () => {

        if(password !== repeatedPassword){
            toast.error('Las contraseñas no coinciden')
            return
        }
      
        setIsloading(true)
        fetch(`${process.env.REACT_APP_BACKEND_URL}auth/change-password`,
            {
                method:"PUT",
                body:JSON.stringify({password: password}),
                headers:{
                "Content-Type":"application/json",
                "Authorization":`Bearer ${token}`
                }
            }
        )
        .then( response => {

            setIsloading(false)
            if(response.ok) {
                toast.success('Contraseña actualizada')
                return
            }

            return Promise.reject(response)
            
        })
        .catch(error => {
            setIsloading(false)
            toast.error('No se pudo actualizar la contraseña')
        })
  
    };
  
    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      if(searchParams.get('token'))
        setToken(searchParams.get('token'))
    }, [location]);
    
  

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={logo} style={{width:30+'vw'}}alt="" />
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h4>{RecoverPasswordTittle}</h4>
                          <h6>{RecoverPasswordSubtittle} </h6>
                        </div>
                        <form className="theme-form">
                          <div className="form-group">
                            <label className="col-form-label">{Password}</label>
                            <input className="form-control" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">{RepeatPassword}</label>
                            <input className="form-control" type="password" name="password" value={repeatedPassword} onChange={(e) => setRepeatedPassword(e.target.value)} />
                          </div>
                          <div className="form-group form-row mt-3 mb-0 d-grid">
                            <button className="btn btn-primary" type="button" onClick={() => updatePassword()}>
                              {UpdatePasswordButton}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          <LoadingSpinner isVisible={isLoading} />
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;