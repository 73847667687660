import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component'
import useCrud from '../../hooks/use_crud';
import "react-toastify/dist/ReactToastify.css";
import "./roles_view.css";
import LoadingSpinner from '../../components/my-components/loading_spinner';
import CreateRoleModal from './create_role_modal';
import IconButton from '../../components/my-components/my_icon_button';
import EditRoleModal from './edit_role_modal';
import { add_text, delete_modal_text, delete_text, dropdown_default_text, edit_text, filters_text, role_classname_text, role_name_filter_text, role_page_filter_text, role_permissions_dropdown, role_title_text } from '../../constant';
import { permissions } from '../../constant/constants';
import Breadcrumb from '../../components/common/breadcrumb';
import ConfirmationModal from '../../components/my-components/confirmation-modal';


const RolesView = () =>{

    const [loading,setLoading] = useState(false)
    const {list, create, edit, Delete, find, totalElements} = useCrud({url: "auth/roles", setLoading: setLoading, firstLoad:false})
    const [selectedPermission, setSelectedPermission] = useState(null);
    const [selectedName, setSelectedName] = useState("")
    const [selectedRow, setSelectedRow] = useState({pages: []})

    const [filters, setFilters] = useState({});
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
    const [confirmationModalData, setConfirmationModalData] = useState({
        description:'',
        onSubmit:()=>{},
        onCancel:()=>setIsConfirmationModalOpen(false)
    })

    
    const onCreate = async (role) => {
        if(!role.name || role.name === ""){
                toast.error("There are some empty fields. Please, try again")
                return
            }

        

          create(role)
          .catch(error => toast.error("Upps, there are some errors. Please, try again")
      )
      
      setCreateModalIsOpen(false);
    }

    const onEdit = async (role) => {

        if(!role.name || role.name === ""){
                toast.error("There are some empty fields. Please, try again")
                return
            }
/*
        if(user.roles[0].id === selectedUser.roles[0].id){
                toast.error("There are no changes")
                return
            }*/

            edit(role)
          .catch(error => toast.error("Upps, there are some errors. Please, try again")
      )
      
      setEditModalIsOpen(false);
    }

    useEffect(()=>{
        find(filters)
    },[filters])
    
   
    useEffect(()=>{
        let filtersAux = {}
        if(selectedPermission && selectedPermission !="-1")
           filtersAux = {
                            ...filtersAux,
                            permissions: [selectedPermission]
                        }

        if(selectedName!="")
           filtersAux = {
                            ...filtersAux,
                            name: selectedName
                        }
        
        setFilters(filtersAux)
    },[selectedPermission,selectedName])

    

    const columns = [
      {name: 'Id de Rol',minWidth: '200px',selector: (row) =>row.id,sortable: true,center:true,},
      {name: 'Nombre',selector: (row) =>row.name,minWidth: '130px',sortable: true,center:true,},
      {name: 'Rol Cliente',selector: (row) =>row.defaultRole?"Sí":"No",minWidth: '200px',sortable: true,center:true,},
      {name: 'Rol Conductor',selector: (row) =>row.defaultDriverRole?"Sí":"No",minWidth: '200px',sortable: true,center:true,},
      {name: 'Rol Operador',selector: (row) =>row.defaultOfficeRole?"Sí":"No",minWidth: '200px',sortable: true,center:true,},
      {name: 'Permisos',selector: (row) =>{
            let permissionsAux = ""
            if(row.permissions)
                row.permissions.map(permission => permissionsAux+=role_permissions_dropdown[permission]+" ")

            return permissionsAux
        }, minWidth: '130px' ,sortable: false,center:true},
      {name: 'Acciones',selector: (row) =>row.actions, minWidth: '130px',sortable: false,center:true},

  ];

    const handleDelete = (id) => {
        setIsConfirmationModalOpen(true)
        setConfirmationModalData({
            ...confirmationModalData,
            description: `${delete_modal_text}${role_classname_text}?`,
            onSubmit: async ()=>{
                await Delete(id)
                setIsConfirmationModalOpen(false)
            }
        })
    };

    const handlePageChange = page => {
        find(filters,{size:10,page:page-1});
      };


    const handlePerRowsChange = async (newPerPage, page) => {
        find(filters,{size:newPerPage,page:page});

      };
    

    return (
        <div>
            <Breadcrumb title={role_title_text}/>
            <br/>
            <div className="row">
                <div className="col">
                    <label>{role_page_filter_text}</label>
                    <select className="form-control digits" id="exampleFormControlSelect23" onChange={(evt)=>{setSelectedPermission(evt.target.value)}}>
                        <option value={-1}>{dropdown_default_text}</option>
                        {
                           permissions.map(permission => <option value={permission}>{role_permissions_dropdown[permission]}</option>)
                        }
                    </select>
                </div>
                <div className="col">
                    <label>{role_name_filter_text}</label>
                    <input className="form-control" type="text" autocomplete="off" style={{marginLeft: 10}} placeholder='Nombre' value={selectedName} onChange={(evt)=>{setSelectedName(evt.target.value)}} />
                </div>
            </div>
            <br/>
            <div style={{display:"flex"}}>
                <div className='btn-right-container'>
                    <Button className='btn btn-primary' color="primary" onClick={()=>{setCreateModalIsOpen(true)}}>{add_text}</Button>
                </div>
            </div>
            <DataTable
                pagination 
                paginationServer
                paginationComponentOptions ={{
                    noRowsPerPage:true
                }}
                paginationTotalRows={totalElements} 
                onChangeRowsPerPage={handlePerRowsChange} 
                onChangePage={handlePageChange}
                striped={true}
                center={true}
                columns={columns}
                data={list.map(row => {
                    return {
                      ...row,
                      actions: <div className='flex'>
                                    <IconButton icon={
                                        <span className='action-box large update-btn'>
                                            <i className='icon'>
                                                <i className='icon-pencil'></i>
                                            </i>
                                        </span>
                                        }
                                        text={edit_text}
                                        onClick={()=>{setSelectedRow(row);setEditModalIsOpen(true); }}
                                        color="warning"
                                    />
                                    <IconButton icon={
                                        <span className='action-box large delete-btn' title='Delete Task'>
                                            <i className='icon'>
                                                <i className='icon-trash'></i>
                                            </i>
                                        </span>
                                        }
                                        text={delete_text}
                                        onClick={()=>{handleDelete(row.id)}}
                                        color="danger"
                                    />
                                </div>
                    }
                  })}
                />
            
            
            <CreateRoleModal 
                isVisible= {createModalIsOpen} 
                onCreate={onCreate} 
                onCancel= {()=>{setCreateModalIsOpen(false)}}
            />

            <EditRoleModal 
                isVisible= {editModalIsOpen} 
                onEdit={onEdit} 
                onCancel= {()=>{setEditModalIsOpen(false)}}
                oldRow={selectedRow}
            />
            <LoadingSpinner isVisible={loading} />
            <ToastContainer />
            <ConfirmationModal 
                isVisible={isConfirmationModalOpen} 
                description={confirmationModalData.description} 
                onSubmit={confirmationModalData.onSubmit} 
                onCancel={confirmationModalData.onCancel}
                />
        </div>
      
      )
}

export default RolesView;