import React, { useState, useEffect } from "react";
import logo from "../assets/images/endless-logo.png";
import man from "../assets/images/user/1.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Password, RepeatPassword, UpdatePasswordButton, RecoverPasswordTittle, RecoverPasswordSubtittle, Email, SendMessage, SendEmail, SendRecoverPasswordSubtittle } from "../constant";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactReduxContext } from "react-redux";
import LoadingSpinner from "../components/my-components/loading_spinner";
import { emailValidator } from "../utils/validators";

const SendRecoverPasswordEmailView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsloading] = useState(false)
    const [email, setEmail] = useState('')
    
    const sendEmail = () => {

        const validationResult = emailValidator(email)

        if(typeof validationResult === 'string')
            toast.error(validationResult)

        setIsloading(true)
        fetch(`${process.env.REACT_APP_BACKEND_URL}auth/send-recover-password-email?email=${email}`)
        .then( response => {

            setIsloading(false)
            if(response.ok) {
                toast.success('Se ha enviado un correo de verificación a su cuenta')
                return
            }

            return Promise.reject(response)
            
        })
        .catch(error => {
            setIsloading(false)
            toast.error('No se pudo enviar el correo a su cuenta, por favor intente mas tarde')
        })
  

    }
  

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={logo} style={{width:30+'vw'}}alt="" />
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h4>{RecoverPasswordTittle}</h4>
                          <h6>{SendRecoverPasswordSubtittle} </h6>
                        </div>
                        <form className="theme-form">
                          <div className="form-group">
                            <label className="col-form-label">{Email}</label>
                            <input className="form-control" type="text"  value={email} onChange={(e) => setEmail(e.target.value)} />
                          </div>
                          <div className="form-group form-row mt-3 mb-0 d-grid">
                            <button className="btn btn-primary" type="button" onClick={() => sendEmail()}>
                              {SendEmail}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          <LoadingSpinner isVisible={isLoading} />
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default SendRecoverPasswordEmailView;