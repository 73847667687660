import React from "react";
import Default from "../components/dashboard/defaultCompo/default";
import UsersView from "../pages/users/users_view";
import RolesView from "../pages/roles/roles_view";
import UserEdit from "../components/users/userEdit";
import AddressesView from "../pages/addresses/addresses_view";
import OrdertypesView from "../pages/orderstypes/order_types_view";
import OrdersView from "../pages/orders/orders_view";
import OrderTypeDetails from "../pages/ordertype_details/ordertype_details";
import ServiceView from "../pages/service/service_view";
import ServiceRequestView from "../pages/service_request/service_request_view";
import DriverOrdersView from "../pages/driver_orders/driver_orders_view";
import OrderReportsView from "../pages/order_reports/order_reports_view";
import ServiceRequestReportsView from "../pages/service_reports/service_request_reports_view";
import PaymentTypeView from "../pages/payment_types/payment_type_view";
import OrderDetails from "../pages/order_details/order_details";


export const routes = [
  { path: `${process.env.PUBLIC_URL}/dashboard/default`, Component: <Default /> },
  
  //Users
  { path: `${process.env.PUBLIC_URL}/users`, Component: <UsersView/>, permissions:["ADMIN"] },
  { path: `${process.env.PUBLIC_URL}/roles`, Component: <RolesView/>, permissions:["ADMIN"]  },
  
  //Addresses
  { path: `${process.env.PUBLIC_URL}/addresses`, Component: <AddressesView/>, permissions:["ADMIN"]  },


  // User
  { path: `${process.env.PUBLIC_URL}/users/userEdit`, Component: <UserEdit />, permissions:"ALL"  },


  //Orders
  { path: `${process.env.PUBLIC_URL}/orders`, Component: <OrdersView/>, permissions:["ADMIN","OPERATOR"]  },
  { path: `${process.env.PUBLIC_URL}/orders/details`, Component: <OrderDetails/>, permissions:["ADMIN","OPERATOR"] },
  { path: `${process.env.PUBLIC_URL}/orders/ordertypes`, Component: <OrdertypesView/>, permissions:["ADMIN"] },
  { path: `${process.env.PUBLIC_URL}/orders/ordertypes/details`, Component: <OrderTypeDetails/>, permissions:["ADMIN"] },

  { path: `${process.env.PUBLIC_URL}/orders/driver`, Component: <DriverOrdersView/>, permissions:["DRIVER"]  },

  //Services
  { path: `${process.env.PUBLIC_URL}/services`, Component: <ServiceView/>, permissions:["ADMIN"] },
  { path: `${process.env.PUBLIC_URL}/services/requests`, Component: <ServiceRequestView/>, permissions:["ADMIN","OPERATOR"] },

  //Reports
  { path: `${process.env.PUBLIC_URL}/orders/report`, Component: <OrderReportsView/>, permissions:["ADMIN","OPERATOR"]  },
  { path: `${process.env.PUBLIC_URL}/services/report`, Component: <ServiceRequestReportsView/>, permissions:["ADMIN","OPERATOR"]  },

  //Payments
  { path: `${process.env.PUBLIC_URL}/payments/types`, Component: <PaymentTypeView/>, permissions:["ADMIN","OPERATOR"]  },

];
