import React, { useEffect } from 'react';
import './order_card.css';
import logo from "../../assets/images/xologas-logo.jpg";
import { Button } from 'reactstrap';
import { driver_order_date_text, driver_order_prize_text } from '../../constant';


const OrderCard = ({order = {}, buttons= []}) => {

    const getFieldName = (orderType, fieldId)=>{
        let extrafield = orderType.extrafields.filter(field => field.id == fieldId)[0];
        return extrafield? extrafield.name: ''
    }

  return (
    <div style={{padding:'0px 20px 0px 20px'}}>
        <div className='card'>
        <div className='card-body'>
                <div className='order-card-body'>
                    <img className='order-card-img' src={logo}/>
                        <div style={{width:'100%'}}>
                            <div style={{display:'flex', width:'100%'}}>
                                <div className='order-card-data-col'>
                                    <h5><b>{order.orderType && order.orderType.name}</b></h5>
                                    <div className='order-card-data'>
                                        <div className='order-card-data'><b>{driver_order_date_text}</b>{(new Date(order.deliveryAppoinment)).toLocaleDateString()}</div>
                                        {
                                            order.extraFields &&
                                            order.extraFields.map(field => {
                                                    let fieldName = getFieldName(order.orderType, field.fieldId)
                                                    
                                                    if(fieldName == '')
                                                        return <></>

                                                    return <div className='order-card-data'><b>{fieldName+': '}</b>{field.value}</div>
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='order-card-data-rigth-col'>
                                    <div>
                                        <div className='order-card-data-rigth-col-data'><b>{driver_order_prize_text}</b></div>
                                        <p className='order-card-data-prize'>{order.prize} MXN</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <br/>
                            <div className= 'order-buttons-container'style={{display:'flex', justifyContent:'end'}}>
                                {
                                    buttons.map(button => button)
                                }
                            </div>
            </div>
        </div>
    </div>
  );
};

export default OrderCard;