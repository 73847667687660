import { useEffect, useState } from "react";
import { create_modal_submit_text, create_modal_text, modal_cancel_text, role_classname_text,role_default_office_role_text, role_default_driver_role_text,role_default_role_text, role_name_filter_text, role_page_filter_text, role_permissions_dropdown } from "../../constant";
import { permissions } from '../../constant/constants';
import { checklistRequiredValidator, notRequiredValidator, requiredValidator, showFormErrors, validateForm } from "../../utils/validators";
import { toast } from "react-toastify";



const CreateRoleModal = ({isVisible, onCreate, onCancel}) => {

    const defaultModel = {
        name:"",
        defaultRole:false,
        defaultDriverRole:false,
        defaultOfficeRole:false,
        permissions:[]
    }

    const [model, setModel] = useState(defaultModel)

    const reset = ()=>{
        setModel({
            name:"",
            defaultRole:false,
            defaultDriverRole:false,
            defaultOfficeRole:false,
            permissions:[]
        })
    }

    const validators = {
            name:requiredValidator('nombre'),
            defaultRole:notRequiredValidator,
            defaultDriverRole:notRequiredValidator,
            defaultOfficeRole:notRequiredValidator,
            permissions:checklistRequiredValidator('permiso')
    }

    const onSubmit = () => {
        
        const validationResult = validateForm(model, validators)
        if(validationResult.isValid){
            onCreate(model)
        }
        else
            showFormErrors(validationResult, toast)
    } 

      

    return (
        <div id="modal" className="my-modal-container" style={isVisible?{display:"block"}:{display:"none"}}>
            <div className="modal-content">
                <div className="card">
                <div className="card-header">
                    <h5>{create_modal_text+role_classname_text}</h5>
                </div>
                <form className="form theme-form">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput20">{role_name_filter_text}</label>
                                    <input className="form-control" id="exampleFormControlInput20" type="text"   value={model.name} onChange={(evt)=>setModel({...model,name:evt.target.value})}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-sm-12">
                            <div className="btn-group btn-option">   
                                <div className="checkbox checkbox-primary">
                                    <input id={`create-role-default-check-button`} checked={model.defaultRole} type="checkbox" onClick={evt => {setModel({...model,defaultRole: evt.target.checked})}}/> 
                                    <label htmlFor="create-role-default-check-button">{role_default_role_text}</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-sm-12">
                            <div className="btn-group btn-option">   
                                <div className="checkbox checkbox-primary">
                                    <input id={`create-role-default-driver-role-check-button`} checked={model.defaultDriverRole} type="checkbox" onClick={evt => {setModel({...model,defaultDriverRole: evt.target.checked})}}/> 
                                    <label htmlFor="create-role-default-driver-role-check-button">{role_default_driver_role_text}</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-sm-12">
                            <div className="btn-group btn-option">   
                                <div className="checkbox checkbox-primary">
                                    <input id={`create-role-default-office-role-check-button`} checked={model.defaultOfficeRole} type="checkbox" onClick={evt => {setModel({...model,defaultOfficeRole: evt.target.checked})}}/> 
                                    <label htmlFor="create-role-default-office-role-check-button">{role_default_office_role_text}</label>
                                </div>
                            </div>
                        </div>
                        <h2 className="card-header">{role_page_filter_text}</h2>
                        {
                            permissions.map(permission => 
                                <div className="col-xl-6 col-sm-12">
                                    <div className="btn-group btn-option" data-toggle="buttons">   
                                        <div className="checkbox checkbox-primary">
                                            <input id={`create-role-page-checkbox-${permission}`} checked={model.permissions.filter(permissionAux => permissionAux == permission)[0]} type="checkbox" onClick={evt => {
                                                    if(evt.target.checked)
                                                            setModel({...model,permissions: [...model.permissions,permission]})
                                                    else 
                                                        setModel({...model,permissions: model.permissions.filter(permissionAux => permissionAux != permission)})
                                                }}/>
                                            <label htmlFor={`create-role-page-checkbox-${permission}`}>{role_permissions_dropdown[permission]}</label>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        
                    </div>
                    <div className="card-footer text-end">
                        <button className="btn btn-primary me-2" type="button" onClick={()=>{onSubmit(); reset();}} >{create_modal_submit_text}</button>
                        <button className="btn btn-light" type="reset" onClick={()=>{reset(); onCancel();}} >{modal_cancel_text}</button>
                    </div>
                </form>
                </div>
            </div>
            </div>
    )
}

export default CreateRoleModal;