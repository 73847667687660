import { useEffect, useState } from "react";
import { order_assign_modal_title_text, order_assign_modal_driver_field_text, modal_cancel_text, service_classname_text,order_on_assign_button_text, service_description_field_text, service_prize_field_text, service_name_field_text, DatePickers, driver_order_reject_modal_title, modal_accept_text } from "../../constant";
import AutoCompleteTextField from "../../components/my-components/autocomplete_text_field";
import { filter } from "lodash";
import useCrud from "../../hooks/use_crud";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import LoadingSpinner from "../../components/my-components/loading_spinner";
import { Button } from "reactstrap";


const RejectOrderModal = ({isVisible, onSubmit = ()=>{}, onCancel = ()=>{}}) => {


    const [reason, setReason] = useState('')
    
    const onAccept = async () =>{
        onSubmit(reason)
        reset()
    }

    const reset = ()=>{
        setReason('')
    }
    
    return (
        <div id="modal" className="my-modal-container" style={isVisible?{display:"block"}:{display:"none"}}>
            <div className="modal-content">
                <div className="card">
                    <div className="card-header">
                        <h5>{driver_order_reject_modal_title}</h5>
                    </div>
                    <div className="form theme-form">
                        <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <textarea className="form-control" value={reason} onChange={(evt)=>setReason(evt.target.value)}/>
                                </div>
                            </div>
                        </div>  
                        </div>
                        
                        <div className="card-footer text-end">
                            <Button className="btn btn-primary me-2" style={{padding:"4px"}} type="button" onClick={()=>{onAccept(); }} >{modal_accept_text}</Button>
                            <Button className="btn btn-light" type="reset" style={{padding:"4px"}} onClick={()=>{ onCancel(); reset();}} >{modal_cancel_text}</Button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
            </div>
    )
}

export default RejectOrderModal;