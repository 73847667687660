import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Password, RepeatPassword, UpdatePasswordButton, modal_cancel_text, RecoverPasswordSubtittle, UpdatePasswordTittle } from "../../constant";
import { passwordValidator } from "../../utils/validators";


const ChangePasswordModal = ({isVisible, onClose}) => {
    const [isLoading, setIsloading] = useState(false)
    const [password, setPassword] = useState('')
    const [repeatedPassword, setRepeatedPassword] = useState('')
  
  
  
    const updatePassword = async () => {

        if(password !== repeatedPassword){
            toast.error('Las contraseñas no coinciden')
            return
        }
        
        const passwordValidatorResult = passwordValidator(password)
        if(typeof passwordValidatorResult === 'string'){
          toast.error(passwordValidatorResult)
          return
        }
      
        setIsloading(true)
        fetch(`${process.env.REACT_APP_BACKEND_URL}auth/change-password`,
            {
                method:"PUT",
                body:JSON.stringify({password: password}),
                headers:{
                "Content-Type":"application/json",
                "Authorization":`Bearer ${localStorage.getItem("userToken")}`
                }
            }
        )
        .then( response => {

            setIsloading(false)
            if(response.ok) {
                toast.success('Contraseña actualizada')
                return
            }

            return Promise.reject(response)
            
        })
        .catch(error => {
            setIsloading(false)
            toast.error('No se pudo actualizar la contraseña')
        })
  
    };
  
    const reset = () => {
        setPassword('')
        setRepeatedPassword('')
    }
    
  

  return (
    <div id="modal" className="my-modal-container" style={isVisible?{display:"block"}:{display:"none"}}>
            <div className="modal-content">
                <div className="card">
                <div className="text-center">
                          <h4>{UpdatePasswordTittle}</h4>
                          <h6>{RecoverPasswordSubtittle} </h6>
                        </div>
                        <form className="theme-form">
                          <div className="form-group">
                            <label className="col-form-label">{Password}</label>
                            <input className="form-control" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">{RepeatPassword}</label>
                            <input className="form-control" type="password" name="password" value={repeatedPassword} onChange={(e) => setRepeatedPassword(e.target.value)} />
                          </div>
                          <div className="form-group form-row mt-3 mb-0 d-grid">
                            <button className="btn btn-primary" type="button" onClick={() => { reset(); updatePassword();}}>
                              {UpdatePasswordButton}
                            </button>
                            <button className="btn btn-light" style={{marginTop:"5px"}} type="reset" onClick={()=>{reset(); onClose();}} >{modal_cancel_text}</button>
                          </div>
                        </form>
                </div>
            </div>
        </div>
  );
};

export default ChangePasswordModal;