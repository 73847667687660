import { useEffect, useState } from "react";
import IconButton from "../../components/my-components/my_icon_button";
import CreateFieldModal from "./create_field_modal";
import { edit_modal_submit_text, edit_modal_text, delete_text, modal_cancel_text,order_type_iva_field_text, order_type_classname_text, order_type_description_field_text, order_type_extrafields_field_text, order_type_name_field_text, order_type_prize_evaluation_expression_field_text } from "../../constant";
import PrizeExpressionKeyboard from "./prize_expression_keyboard";
import { checklistRequiredValidator, notRequiredValidator, requiredValidator, showFormErrors, validateForm } from "../../utils/validators";
import { toast } from "react-toastify";


const EditOrderTypeModal = ({isVisible, oldRow={}, onEdit, onCancel, }) => {


    const [newRow, setNewRow] = useState(oldRow)
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [expressionKeyboardIsOpen, setExpressionKeyboardIsOpen] = useState(false);

    const validators = {
            name:requiredValidator('nombre'),
            description:notRequiredValidator,
            prizeEvaluationExpression:requiredValidator('fórmula del percio'),
            iva:requiredValidator('iva'),
            extrafields:checklistRequiredValidator('campos específicos')
    }

    const onSubmit = () => {
        console.log(newRow)
        const validationResult = validateForm(newRow, validators)
        if(validationResult.isValid){
            onEdit(newRow)
        }
        else
            showFormErrors(validationResult, toast)
    } 

    useEffect(()=>{
        setNewRow(oldRow)
    },[oldRow])

    return (
        <div id="modal" className="my-modal-container" style={isVisible?{display:"block"}:{display:"none"}}>
            <div className="modal-content">
                <div className="card">
                    <div className="card-header">
                        <h5>{edit_modal_text+order_type_classname_text}</h5>
                    </div>
                    <div className="form theme-form">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput20">{order_type_name_field_text}</label>
                                        <input className="form-control" id="exampleFormControlInput20" type="email" placeholder="nombredeejemplo" value={newRow.name} onChange={(evt)=>setNewRow({...newRow,name:evt.target.value})}/>
                                    </div>
                                </div>
                            </div>   
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput20">{order_type_description_field_text}</label>
                                        <textarea className="form-control" id="exampleFormControlInput20" type="email"  value={newRow.description} onChange={(evt)=>setNewRow({...newRow,description:evt.target.value})}/>
                                    </div>
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput20">{order_type_prize_evaluation_expression_field_text}</label>
                                        <input className="form-control" id="exampleFormControlInput20" type="text" onClick={()=>setExpressionKeyboardIsOpen(true)} placeholder="nombredeejemplo" value={newRow.prizeEvaluationExpression}/>
                                    </div>
                                </div>
                            </div>    
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput20">{order_type_iva_field_text}</label>
                                        <input className="form-control" id="exampleFormControlInput20" type="number" value={newRow.iva?newRow.iva:0} onChange={(evt)=>setNewRow({...newRow,iva:evt.target.value})}/>
                                    </div>
                                </div>
                            </div>  
                            <div >
                                <h4>{order_type_extrafields_field_text}<button onClick={()=>setCreateModalIsOpen(true)}>+</button></h4>
                                {
                                newRow.extrafields.map(field => <div className="field">
                                    <div className="field-text">{`${field.name}(${field.codeName})`}</div>
                                <div className="close-icon">
                                <IconButton icon={
                                    <span className='action-box large delete-btn' title='Delete Task'>
                                        <i className='icon'>
                                            <i className='icon-trash'></i>
                                        </i>
                                    </span>
                                    }
                                    text="Delete"
                                    onClick={()=>{setNewRow({...newRow,extrafields:newRow.extrafields.filter(fieldAux=> fieldAux.name!=field.name)})}}
                                    color="danger"
                                    /> 
                                    </div>
                                </div>)
                                }
                                
                            </div>                     
                        </div>
                        
                        <div className="card-footer text-end">
                            <button className="btn btn-primary me-2" type="button" onClick={()=>{onSubmit(); }} >{edit_modal_submit_text}</button>
                            <button className="btn btn-light" type="reset" onClick={()=>{ onCancel();}} >{modal_cancel_text}</button>
                        </div>
                    </div>
                    <PrizeExpressionKeyboard 
                    isVisible={expressionKeyboardIsOpen} 
                    onClose={()=>setExpressionKeyboardIsOpen(false)} 
                    expression={newRow.prizeEvaluationExpression?newRow.prizeEvaluationExpression:""} 
                    setExpression={(expression)=>{setNewRow({...newRow,prizeEvaluationExpression:expression})}} 
                    extraButtons={newRow.extrafields
                        .filter(field => field.type!="text")
                        .map(field => field.codeName)
                    }
                    />
                    <CreateFieldModal 
                        isVisible= {createModalIsOpen} 
                        onCreate={(field)=>{setNewRow({...newRow,extrafields:[...newRow.extrafields,field]});setCreateModalIsOpen(false);}} 
                        onCancel= {()=>{setCreateModalIsOpen(false)}}
                        />
                </div>
            </div>
            </div>
    )
}

export default EditOrderTypeModal;