import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component'
import useCrud from '../../hooks/use_crud';
import "react-toastify/dist/ReactToastify.css";
import "./order_reports_view.css";
import LoadingSpinner from '../../components/my-components/loading_spinner';
import DatePicker from "react-datepicker";
import IconButton from '../../components/my-components/my_icon_button';
import {order_breadcrumb_title_text, delete_modal_text,order_start_date_filter_text, order_end_date_filter_text, delete_text, dropdown_default_text, filters_text, order_assign_button_text, order_state_filter_text, order_statuses_dropdown_texts, order_title_text, order_type_filter_text, role_classname_text, download_as_excel } from '../../constant';
import Breadcrumb from '../../components/common/breadcrumb';
import { Download} from "react-feather";
import * as XLSX from 'xlsx';
import ConfirmationModal from '../../components/my-components/confirmation-modal';
import { formatNumberWithCurrency } from '../../utils/formatters';


const OrderReportsView = () =>{

    const [loading,setLoading] = useState(false)
    const {list, find, findAll,Delete, totalElements, reload} = useCrud({url: "orders", setLoading: setLoading, firstLoad:false})
    const {list: orderTypes} = useCrud({url: "ordertypes", setLoading: setLoading})
    const [selectedOrder,setSelectedOrder] = useState({});

    const [selectedOrderType, setSelectedOrderType] = useState({
        id:-1,
        name: "Tipo"
    });

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const [filters, setFilters] = useState({});
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
    const [confirmationModalData, setConfirmationModalData] = useState({
        description:'',
        onSubmit:()=>{},
        onCancel:()=>setIsConfirmationModalOpen(false)
    })



    useEffect(()=>{
        find(filters)
    },[filters])
    
   
    useEffect(()=>{
        let filtersAux = {
            status:'COMPLETED'
        }
       
        if(selectedOrderType.id!=-1)
            filtersAux= {
                    ...filtersAux,
                    orderType:{id:selectedOrderType.id}
            }

        if(startDate && endDate) {
            filtersAux = {
                ...filtersAux,
                "createdAt $between": [startDate.getTime(),endDate.getTime()]
            }
        }

        setFilters(filtersAux)
    },[selectedOrderType,startDate,endDate])

   
    

    const columns = [
      {name: 'Id de Orden',minWidth: '200px',selector: (row) =>row.id,sortable: true,center:true,},
      {name: 'Tipo',minWidth: '200px',selector: (row) =>row.orderType.name,sortable: true,center:true,},
      {name: 'Cliente',minWidth: '200px',selector: (row) =>row.user.name,sortable: true,center:true,},
      {name: 'Estado',selector: (row) =>order_statuses_dropdown_texts[row.status],sortable: true,center:true,},
      {name: 'Fecha de creada',selector: (row) => (new Date(row.createdAt)).toLocaleDateString(),minWidth: '200px',sortable: true,center:true,},
      {name: 'Precio',selector: (row) =>formatNumberWithCurrency(row.prize),sortable: true,center:true,},
      {name: 'Requiere Factura',selector: (row) =>row.invoiceRequired?"Sí":"No",minWidth: '200px',sortable: true,center:true,},
      {name: 'Acciones',selector: (row) =>row.actions,sortable: false,center:true},

  ];

    const handleDelete = (id) => {

        setIsConfirmationModalOpen(true)
        setConfirmationModalData({
            ...confirmationModalData,
            description: `${delete_modal_text}${role_classname_text}?`,
            onSubmit: async ()=>{
                await Delete(id)
                setIsConfirmationModalOpen(false)
            }
        })
    };

    const handlePageChange = page => {
        find(filters,{size:10,page:page-1});
      };


    const handlePerRowsChange = async (newPerPage, page) => {
        find(filters,{size:newPerPage,page:page});

      };

    const handleExportToExcel = async () => {
        const data = await findAll();
        
        
        // Crear un nuevo libro de trabajo
        const workbook = XLSX.utils.book_new();
        
        // Procesar los datos para el formato adecuado
        const formattedData = data.map(item => ({
            'Id': item.id,
            'Precio': item.prize,
            'Requiere Factura': item.invoiceRequired?'Sí':'No',
            'Fecha de creada': new Date(item.createdAt).toLocaleString(), // Formatear la fecha
            'Fecha de entrega': new Date(item.deliveryAppoinment),
            'Tipo de Orden': item.orderType.name, // Extraer solo el nombre del tipo de orden
            //extraFields: item.extraFields.map(field => `${field.name}: ${field.value}`).join(', ') // Unir campos extra
        }));

        // Convertir los datos a una hoja de cálculo
        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        
        // Establecer el ancho de las columnas
        worksheet['!cols'] = [
            { wch: 5 }, 
            { wch: 10 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
        ];

        // Agregar la hoja al libro
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');

        // Generar el archivo Excel y forzar su descarga
        XLSX.writeFile(workbook, 'orders.xlsx');
    }
    
    return (
        <div>
            <Breadcrumb parent={order_title_text} title={order_breadcrumb_title_text}/>
            <br/>
            <div className="row">
                <div className="col">
                    <label>{order_type_filter_text}</label>
                    <select className="form-control digits" id="exampleFormControlSelect23" onChange={(evt)=>{setSelectedOrderType({id: parseInt(evt.target.value)})}}>
                        <option value={-1}>{dropdown_default_text}</option>
                        {
                           orderTypes.map(orderType => <option value={orderType.id}>{orderType.name}</option>)
                        }
                    </select>
                </div>
                <div className='col'>
                    <label>{order_start_date_filter_text}</label>
                    <DatePicker className="form-control" showIcon icon="fa fa-calendar" selected={startDate} onChange={(date) => setStartDate(date)} />
                </div>
                <div className='col'>
                    <label>{order_end_date_filter_text}</label>
                    <DatePicker className="form-control" showIcon icon="fa fa-calendar" selected={endDate} onChange={(date) => setEndDate(date)} />
                </div>
            </div>
            <br/>
            <div style={{display:"flex"}}>
                <div className='btn-right-container'>
                <IconButton icon={<Download size={18}/>}
                                    text={download_as_excel}
                                    onClick={()=>{handleExportToExcel()}}
                                    color="danger"
                                />
                </div>
            </div>
            <br/>
            <DataTable
                pagination 
                paginationServer
                paginationComponentOptions ={{
                    noRowsPerPage:true
                }}
                paginationTotalRows={totalElements} 
                onChangeRowsPerPage={handlePerRowsChange} 
                onChangePage={handlePageChange}
                striped={true}
                center={true}
                columns={columns}
                data={list.map(row => {
                    return {
                      ...row,
                      /*actions: <div className='flex'>
                                    <IconButton icon={
                                        <span className='action-box large delete-btn' title='Delete Task'>
                                            <i className='icon'>
                                                <i className='icon-trash'></i>
                                            </i>
                                        </span>
                                        }
                                        text={delete_text}
                                        onClick={()=>{handleDelete(row.id)}}
                                        color="danger"
                                    />
                                </div>*/
                    }
                  })}
                />
           
            <LoadingSpinner isVisible={loading} />
            <ToastContainer />
            <ConfirmationModal 
                isVisible={isConfirmationModalOpen} 
                description={confirmationModalData.description} 
                onSubmit={confirmationModalData.onSubmit} 
                onCancel={confirmationModalData.onCancel}
                />
        </div>
      
      )
}

export default OrderReportsView;