import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component'
import useCrud from '../../hooks/use_crud';
import "react-toastify/dist/ReactToastify.css";
import "./service_request_reports_view.css";
import LoadingSpinner from '../../components/my-components/loading_spinner';
import { download_as_excel, service_title_text,order_start_date_filter_text,order_end_date_filter_text, address_colony_filter_text, address_municipality_filter_text, address_privated_filter_text, address_title_text, address_type_filter_text, dropdown_default_text, filters_text, service_request_invoice_required_filter_text, service_request_service_filter_text, service_request_status_filter_text, service_request_title_text } from '../../constant';
import Breadcrumb from '../../components/common/breadcrumb';
import { serviceRequestStatuses } from '../../constant/constants';
import DatePicker from "react-datepicker";
import IconButton from '../../components/my-components/my_icon_button';
import { Download} from "react-feather";
import * as XLSX from 'xlsx';
import { formatNumberWithCurrency } from '../../utils/formatters';



const ServiceRequestReportsView = () =>{

    const [loading,setLoading] = useState(false)
    const {list, find, findAll, totalElements} = useCrud({url: "servicerequest", setLoading: setLoading, firstLoad:false})
    const {list: services} = useCrud({url:"services",  setLoading: setLoading, firstLoad: true})
    const [selectedService, setSelectedService] = useState({
        id:-1,
        name: "Service"
    });
    const [selectedInvoiceRequired, setSelectedInvoiceRequired] = useState(undefined);
    const [filters, setFilters] = useState({});
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)


    
    

    useEffect(()=>{
        find(filters)
    },[filters])

   
    useEffect(()=>{
        let filtersAux = {
            status: "DONE"
        }

        if(selectedService.id!=-1)
            filtersAux = {
                ...filtersAux,
                service:{id:parseInt(selectedService.id)}
            }


        if(selectedInvoiceRequired!=undefined)
            filtersAux = {
                ...filtersAux,
                invoiceRequired: selectedInvoiceRequired
            }

        if(startDate && endDate) {
                filtersAux = {
                    ...filtersAux,
                    "createdAt $between": [startDate.getTime(),endDate.getTime()]
                }
            }
        
        setFilters(filtersAux)
    },[selectedService, selectedInvoiceRequired, startDate, endDate])

    const columns = [
      {name: 'Id de Solicitud',minWidth: '200px',selector: (row) =>row.id,sortable: true,center:true,},
      {name: 'Requiere Factura',selector: (row) =>row.invoiceRequired?"Sí":"No",sortable: true,center:true,},
      {name: 'Costo',minWidth: '200px',selector: (row) =>formatNumberWithCurrency(row.prize),sortable: true,center:true,},
      {name: 'Fecha de creada',selector: (row) => (new Date(row.createdAt)).toLocaleDateString(),minWidth: '200px',sortable: true,center:true,},
      {name: 'Servicio',minWidth: '200px',selector: (row) =>row.service.name,sortable: true,center:true,},
      {name: 'Estado',minWidth: '200px',selector: (row) =>row.status?serviceRequestStatuses[row.status]:"",sortable: true,center:true,},
      {name: 'Usuario',minWidth: '200px',selector: (row) =>row.user.email,sortable: true,center:true,},
    ];


    const handlePageChange = page => {
        find(filters,{size:10,page:page-1});
      };


    const handlePerRowsChange = async (newPerPage, page) => {
        find(filters,{size:newPerPage,page:page});

      };
    
      const handleExportToExcel = async () => {
        const data = await findAll();
        
        
        // Crear un nuevo libro de trabajo
        const workbook = XLSX.utils.book_new();
        
        // Procesar los datos para el formato adecuado
        const formattedData = data.map(item => ({
            'Id': item.id,
            'Precio': item.prize,
            'Requiere Factura': item.invoiceRequired?'Sí':'No',
            'Fecha de creada': new Date(item.createdAt).toLocaleString(), // Formatear la fecha
            'Servicio': item.service.name,
            'Cliente': item.user.email
        }));

        // Convertir los datos a una hoja de cálculo
        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        
        // Establecer el ancho de las columnas
        worksheet['!cols'] = [
            { wch: 5 }, 
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
        ];

        // Agregar la hoja al libro
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Services');

        // Generar el archivo Excel y forzar su descarga
        XLSX.writeFile(workbook, 'services.xlsx');
    }

    return (
        <div>
            <Breadcrumb parent={service_title_text} title={service_request_title_text}/>
            <br/>
            <div className="row">
                <div className="col">
                    <label>{service_request_service_filter_text}</label>
                    <select className="form-control digits" id="exampleFormControlSelect23" onChange={(evt)=>{setSelectedService({id: evt.target.value})}}>
                        <option value={-1}>{dropdown_default_text}</option>
                        {
                           services.map(service => <option value={service.id}>{service.name}</option>)
                        }
                    </select>
                </div>
                <div className="col">
                    <label>{service_request_invoice_required_filter_text}</label>
                    <select className="form-control digits"  onChange={(evt)=>{setSelectedInvoiceRequired(evt.target.value=="true"?true:evt.target.value=="false"?false:undefined)}}>
                        <option value={-1}>{"Ninguno"}</option>
                        <option value={true}>{"Sí"}</option>
                        <option value={false}>{"No"}</option>           
                    </select>
                </div>
                <div className='col'>
                    <label>{order_start_date_filter_text}</label>
                    <DatePicker className="form-control" showIcon icon="fa fa-calendar" selected={startDate} onChange={(date) => setStartDate(date)} />
                </div>
                <div className='col'>
                    <label>{order_end_date_filter_text}</label>
                    <DatePicker className="form-control" showIcon icon="fa fa-calendar" selected={endDate} onChange={(date) => setEndDate(date)} />
                </div>
            </div>
            <br/>
            <div style={{display:"flex"}}>
                <div className='btn-right-container'>
                <IconButton icon={<Download size={18}/>}
                                    text={download_as_excel}
                                    onClick={()=>{handleExportToExcel()}}
                                    color="danger"
                                />
                </div>
            </div>
            <br/>
            <DataTable
                pagination 
                paginationServer
                paginationComponentOptions ={{
                    noRowsPerPage:true
                }}
                paginationTotalRows={totalElements} 
                onChangeRowsPerPage={handlePerRowsChange} 
                onChangePage={handlePageChange}
                striped={true}
                center={true}
                columns={columns}
                data={list}
                />
            
            
            <LoadingSpinner isVisible={loading} />
            <ToastContainer />
        </div>
      
      )
}

export default ServiceRequestReportsView;