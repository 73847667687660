import { useEffect, useState } from "react";
import { edit_modal_submit_text, edit_modal_text, modal_cancel_text,role_default_role_text, role_default_driver_role_text, role_classname_text, role_name_filter_text, role_page_filter_text, role_permissions_dropdown } from "../../constant";
import { permissions } from '../../constant/constants';
import { checklistRequiredValidator, notRequiredValidator, requiredValidator, showFormErrors, validateForm } from "../../utils/validators";
import { toast } from "react-toastify";


const EditRoleModal = ({isVisible, oldRow={}, onEdit, onCancel}) => {


    const [newRow, setNewRow] = useState(oldRow)
    
    const validators = {
        name:requiredValidator('nombre'),
        defaultRole:notRequiredValidator,
        defaultDriverRole:notRequiredValidator,
        defaultOfficeRole:notRequiredValidator,
        permissions:checklistRequiredValidator('permiso')
    }

    const onSubmit = () => {
    
        const validationResult = validateForm(newRow, validators)
        if(validationResult.isValid){
            onEdit(newRow)
        }
        else
            showFormErrors(validationResult, toast)
    } 

    useEffect(()=>{
        setNewRow(oldRow)
    },[oldRow])

   
    return (
        <div id="modal" className="my-modal-container" style={isVisible?{display:"block"}:{display:"none"}}>
            <div className="modal-content">
                <div className="card">
                <div className="card-header">
                    <h5>{edit_modal_text+role_classname_text}</h5>
                </div>
                <form className="form theme-form">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput20">{role_name_filter_text}</label>
                                    <input className="form-control" id="exampleFormControlInput20" type="email"   value={newRow.name} onChange={(evt)=>setNewRow({...newRow,name:evt.target.value})}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-sm-12">
                            <div className="btn-group btn-option">   
                                <div className="checkbox checkbox-primary">
                                    <input id={`update-role-default-check-button`} checked={newRow.defaultRole} type="checkbox" onClick={evt => {setNewRow({...newRow,defaultRole: evt.target.checked})}}/> 
                                    <label htmlFor="update-role-default-check-button">{role_default_role_text}</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-sm-12">
                            <div className="btn-group btn-option">   
                                <div className="checkbox checkbox-primary">
                                    <input id={`update-role-default-driver-role-check-button`} checked={newRow.defaultDriverRole} type="checkbox" onClick={evt => {setNewRow({...newRow,defaultDriverRole: evt.target.checked})}}/> 
                                    <label htmlFor="update-role-default-driver-role-check-button">{role_default_driver_role_text}</label>
                                </div>
                            </div>
                        </div>
                        <h2 className="card-header">{role_page_filter_text}</h2>
                        {   
                            permissions.map(permission => 
                                <div className="col-xl-6 col-sm-12">
                                    <div className="btn-group btn-option" data-toggle="buttons">   
                                        <div className="checkbox checkbox-primary">
                                            <input id={`edit-role-page-checkbox-${permission}`} checked={newRow.permissions && newRow.permissions.filter(permissionAux => permission === permissionAux)[0]} type="checkbox" onClick={evt => {
                                                    if(evt.target.checked)
                                                            setNewRow({...newRow,permissions: newRow.permissions?[...newRow.permissions,permission]:[permission]})
                                                    else 
                                                        setNewRow({...newRow,permissions: newRow.permissions.filter(permissionAux => permissionAux !== permission)})
                                                }}/>
                                            <label htmlFor={`edit-role-page-checkbox-${permission}`}>{role_permissions_dropdown[permission]}</label>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        
                    </div>
                    <div className="card-footer text-end">
                        <button className="btn btn-primary me-2" type="button" onClick={()=>{onSubmit(newRow);}} >{edit_modal_submit_text}</button>
                        <button className="btn btn-light" type="reset" onClick={()=>{onCancel();}} >{modal_cancel_text}</button>
                    </div>
                </form>
                </div>
            </div>
            </div>
    )
}

export default EditRoleModal;