import React, { useState, useEffect } from "react";
import logo from "../assets/images/endless-logo.png";
import man from "../assets/images/user/1.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Login, LOGIN, YourName, Password, RememberMe, session_expired } from "../constant";
import { useNavigate, useLocation } from "react-router-dom";

const VerifyEmail = () => {
  
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsloading] = useState(true)




  const verifyEmail = async (token) => {
    
    fetch(`${process.env.REACT_APP_BACKEND_URL}auth/verify/email/${token}`)
    .then( response => response.ok? response.json():Promise.reject(response))
    .then( user => {
        setIsloading(false)
    })
    .catch(error => toast.error(error))

  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if(searchParams.get('token'))
      verifyEmail(searchParams.get('token'))
  }, [location]);

  

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={logo} style={{width:30+'vw'}}alt="" />
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <h1>{isLoading?'Verficando direccion de correo...':'Direccion de correo verificada correctamente, por favor regrese a la aplicacion'}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
