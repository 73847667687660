import React, { useEffect } from 'react';
import './tab.css';
import logo from "../../assets/images/xologas-logo.jpg";
import { Button } from 'reactstrap';


const Tab = ({selected, onClick, text}) => {


  return (
    <button className={selected?'selected-tab':'not-selected-tab'} onClick={onClick} >{text}</button>
  );
};

export default Tab;