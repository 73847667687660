import React, { Fragment, useEffect, useState } from 'react';
import man from '../../../assets/images/dashboard/user.png'
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import {ELANA,GeneralManager} from '../../../constant'
import useCrud from '../../../hooks/use_crud';

const UserPanel = () => {
    const url = '';
    const {findById} = useCrud({url : 'auth', setLoading : ()=>{}, firstLoad: false})
    const [data, setData] = useState({roles:[{name:"none"}]});
    
    useEffect(() => {
   
        findById(localStorage.getItem("userId")).then(user => {
          setData(user)
        })
        
      }, []);
    
    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    <img className="img-60 rounded-circle lazyloaded blur-up" src={url ? url : man} alt="#" />
                    <div className="profile-edit">
                        <Link to={`${process.env.PUBLIC_URL}/users/userEdit`}>
                            <Edit />
                        </Link>
                    </div>
                </div>
                <h6 className="mt-3 f-14">{data.name}</h6>
                <p>{data.roles[0].name}</p>
            </div>
        </Fragment>
    );
};

export default UserPanel;