import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component'
import useCrud from '../../hooks/use_crud';
import "react-toastify/dist/ReactToastify.css";
import "./service_request_view.css";
import LoadingSpinner from '../../components/my-components/loading_spinner';
import { service_title_text, address_colony_filter_text, address_municipality_filter_text, address_privated_filter_text, address_title_text, address_type_filter_text, dropdown_default_text, filters_text, service_request_invoice_required_filter_text, service_request_service_filter_text, service_request_status_filter_text, service_request_title_text } from '../../constant';
import Breadcrumb from '../../components/common/breadcrumb';
import { serviceRequestStatuses } from '../../constant/constants';

const ServiceRequestView = () =>{

    const [loading,setLoading] = useState(false)
    const {list, find, totalElements} = useCrud({url: "servicerequest", setLoading: setLoading, firstLoad:false})
    const {list: services} = useCrud({url:"services",  setLoading: setLoading, firstLoad: true})
    const {list: statuses} = useCrud({url:"servicerequeststatus",  setLoading: setLoading, firstLoad: true})
    const [selectedService, setSelectedService] = useState({
        id:-1,
        name: "Service"
    });
    const [selectedStatus, setSelectedStatus] = useState(-1);
    const [selectedInvoiceRequired, setSelectedInvoiceRequired] = useState(undefined);
    const [filters, setFilters] = useState({});
   


    
    

    useEffect(()=>{
        find(filters)
    },[filters])

   
    useEffect(()=>{
        let filtersAux = {}

        if(selectedService.id!=-1)
            filtersAux = {
                ...filtersAux,
                service:{id:parseInt(selectedService.id)}
            }

        if(selectedStatus!=-1)
            filtersAux = {
                    ...filtersAux,
                    status:selectedStatus
                }

        if(selectedInvoiceRequired!=undefined)
            filtersAux = {
                ...filtersAux,
                invoiceRequired: selectedInvoiceRequired
            }
        
        setFilters(filtersAux)
    },[selectedService, selectedStatus, selectedInvoiceRequired])

    const columns = [
      {name: 'Id de Solicitud',minWidth: '200px',selector: (row) =>row.id,sortable: true,center:true,},
      {name: 'Requiere Factura',selector: (row) =>row.invoiceRequired?"Sí":"No",sortable: true,center:true,},
      {name: 'Costo',minWidth: '200px',selector: (row) =>row.prize,sortable: true,center:true,},
      {name: 'Servicio',minWidth: '200px',selector: (row) =>row.service.name,sortable: true,center:true,},
      {name: 'Estado',minWidth: '200px',selector: (row) => serviceRequestStatuses[row.status],sortable: true,center:true,},
      {name: 'Usuario',minWidth: '200px',selector: (row) =>row.user.email,sortable: true,center:true,},
    ];


    const handlePageChange = page => {
        find(filters,{size:10,page:page-1});
      };


    const handlePerRowsChange = async (newPerPage, page) => {
        find(filters,{size:newPerPage,page:page});

      };
    

    return (
        <div>
            <Breadcrumb parent={service_title_text} title={service_request_title_text}/>
            <br/>
            <div className="row">
                <div className="col">
                    <label>{service_request_service_filter_text}</label>
                    <select className="form-control digits" id="exampleFormControlSelect23" onChange={(evt)=>{setSelectedService({id: evt.target.value})}}>
                        <option value={-1}>{dropdown_default_text}</option>
                        {
                           services.map(service => <option value={service.id}>{service.name}</option>)
                        }
                    </select>
                </div>
                <div className="col">
                    <label>{service_request_status_filter_text}</label>
                    <select className="form-control digits"  value={selectedStatus.id} onChange={(evt)=>{setSelectedStatus(evt.target.value)}}>
                        <option value={-1}>{"Ninguno"}</option>
                        {
                           Object.keys(serviceRequestStatuses).map(status => <option value={status}>{serviceRequestStatuses[status]}</option>)
                        }       
                    </select>
                </div>
                <div className="col">
                    <label>{service_request_invoice_required_filter_text}</label>
                    <select className="form-control digits"  onChange={(evt)=>{setSelectedInvoiceRequired(evt.target.value=="true"?true:evt.target.value=="false"?false:undefined)}}>
                        <option value={-1}>{"Ninguno"}</option>
                        <option value={true}>{"Sí"}</option>
                        <option value={false}>{"No"}</option>           
                    </select>
                </div>
            </div>
            <br/>
            <DataTable
                pagination 
                paginationServer
                paginationComponentOptions ={{
                    noRowsPerPage:true
                }}
                paginationTotalRows={totalElements} 
                onChangeRowsPerPage={handlePerRowsChange} 
                onChangePage={handlePageChange}
                striped={true}
                center={true}
                columns={columns}
                data={list}
                />
            
            
            <LoadingSpinner isVisible={loading} />
            <ToastContainer />
        </div>
      
      )
}

export default ServiceRequestView;