import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
//import { statesData } from './statesdata.js'
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component'
import useCrud from '../../hooks/use_crud';
import "react-toastify/dist/ReactToastify.css";
import "./payment_type_view.css";
import LoadingSpinner from '../../components/my-components/loading_spinner';
import { user_name_filter_text, address_colony_filter_text, address_municipality_filter_text, address_privated_filter_text, address_title_text, address_type_filter_text, dropdown_default_text, filters_text, payment_type_title } from '../../constant';
import Breadcrumb from '../../components/common/breadcrumb';
import { ToggleButton } from 'react-bootstrap';
import XToggleButton from '../../components/my-components/xtoggle_button';


const PaymentTypeView = () =>{

    const [loading,setLoading] = useState(false)
    const {list, reload,edit, totalElements} = useCrud({url: "payment/type",pagination:{page:0,size:50}, setLoading: setLoading, firstLoad:true})

    const columns = [
      {name: 'Id de Tipo de Pago',minWidth: '200px',selector: (row) =>row.id,sortable: true,center:true,},
      {name: 'Nombre',selector: (row) =>row.name,sortable: true,center:true,},
      {name: 'Activo',selector: (row) =>row.active,sortable: true,center:true,},
  ];


    
    const updateTypeActive = async (paymentType) => {
        paymentType.active = !paymentType.active;
        await edit(paymentType);
        toast.success("Actualizado correctamente");
    };

    return (
        <div>
            <Breadcrumb title={payment_type_title} />
            
            <DataTable
                paginationTotalRows={totalElements} 
                striped={true}
                center={true}
                columns={columns}
                data={list.map(row => {
                    return {
                      ...row,
                      active:   <XToggleButton checked={row.active} onClick={()=>{updateTypeActive(row)}} />  
                    }
                  })}
                />
            
            
            <LoadingSpinner isVisible={loading} />
            <ToastContainer />
        </div>
      
      )
}

export default PaymentTypeView;