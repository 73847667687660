import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component'
import useCrud from '../../hooks/use_crud';
import "./users_view.css";
import LoadingSpinner from '../../components/my-components/loading_spinner';
import CreateUserModal from './create_users_modal';
import IconButton from '../../components/my-components/my_icon_button';
import EditUserModal from './edit_user_modal';
import { add_text, delete_modal_text, delete_text, dropdown_default_text, edit_text, filters_text, user_classname_text, user_email_filter_text, user_name_filter_text, user_role_filter_text, user_title_text } from '../../constant';
import Breadcrumb from '../../components/common/breadcrumb';
import ConfirmationModal from '../../components/my-components/confirmation-modal';

const UsersView = () =>{

    const [loading,setLoading] = useState(false)
    const {list, create, edit, Delete, find, totalElements, reload} = useCrud({url: "auth", setLoading: setLoading})
    const {list: roles, find: findRoles} = useCrud({url:"auth/roles",  setLoading: setLoading})
    const [selectedRole, setSelectedRole] = useState({
        id:-1,
        name: "Roles"
    });
    const [selectedUsername, setSelectedUsername] = useState("")
    const [selectedEmail, setSelectedEmail] = useState("")
    const [selectedUser, setSelectedUser] = useState({roles: [{id:-1}]})

    const [filters, setFilters] = useState({});
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
    const [confirmationModalData, setConfirmationModalData] = useState({
        description:'',
        onSubmit:()=>{},
        onCancel:()=>setIsConfirmationModalOpen(false)
    })

    
    const createUser = async (user) => {
        
        if(!user.username || !user.email 
            ||(user.type=="ADMIN" && (!user.roles || user.roles[0].id < 0 )) 
            || !user.name||user.name == "" 
            || !user.familyName || user.familyName == ""){
                toast.error("There are some empty fields. Please, try again")
                return
            }

        if(user.type=="ADMIN" ){
          create({...user, type:undefined})
          .catch(error => toast.error("Upps, there are some errors. Please, try again"))
        }
        else{
            setLoading(true)

        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}auth/create/${user.type}`,
            {
              method:"POST",
              body:JSON.stringify({...user, type:undefined}),
              headers:{
                "Content-Type":"application/json",
                "Authorization":`Bearer ${localStorage.getItem("userToken")}`
              }
            }
          )
          
          setLoading(false)

          if(response.ok){
            setCreateModalIsOpen(false);
            reload()
            return
          }

          toast.error("Upps, there are some errors. Please, try again")
        }

      
      setCreateModalIsOpen(false);
    }

    const editUser = async (user) => {

        if(!user.username || user.username === "" ||!user.roles || user.roles[0].id < 0){
                toast.error("There are some empty fields. Please, try again")
                return
            }

        if(user.roles[0].id === selectedUser.roles[0].id){
                toast.error("There are no changes")
                return
            }

            edit({
            id: user.id,
            username: user.username,
            roles:user.roles
            })
          .catch(error => toast.error("Upps, there are some errors. Please, try again")
      )
      
      setEditModalIsOpen(false);
    }


    useEffect(()=>{
        find(filters)
    },[filters])

    useEffect(()=>{
       findRoles();
    },[])

    useEffect(()=>{
        setLoading(false)
    },[list])

    useEffect(()=>{
        let filtersAux = {}
        if(selectedRole.id!=-1)
           filtersAux = {
                            ...filtersAux,
                            user_role: {role_id:selectedRole.id}
                        }

        if(selectedEmail!="")
           filtersAux = {
                            ...filtersAux,
                            email: selectedEmail
                        }

        if(selectedUsername!="")
           filtersAux = {
                            ...filtersAux,
                            username: selectedUsername
                        }
        
        setFilters(filtersAux)
    },[selectedRole,selectedEmail,selectedUsername])

    

    const columns = [
      {name: 'Id de Usuario',minWidth: '200px',selector: (row) =>row.id,sortable: true,center:true,},
      {name: 'Nombre de usuario',selector: (row) =>row.username,minWidth: '200px',sortable: true,center:true,},
      {name: 'Nombre',selector: (row) =>row.name,minWidth: '200px',sortable: true,center:true,},
      {name: 'Telefono',selector: (row) =>row.cellphone,minWidth: '200px',sortable: true,center:true,},
      {name: 'Email',selector: (row) =>row.email,sortable: true,center:true},
      {name: 'Rol',selector: (row) =>row.roles && row.roles[0]?row.roles[0].name:"",sortable: true,center:true},
      {name: 'Acciones',selector: (row) =>row.actions,sortable: true,center:true},

  ];

    const handleDelete = (id) => {
        setIsConfirmationModalOpen(true)
        setConfirmationModalData({
            ...confirmationModalData,
            description: `${delete_modal_text}${user_classname_text}?`,
            onSubmit: async ()=>{
                await Delete(id)
                setIsConfirmationModalOpen(false)
            }
        })
    };

    const handlePageChange = page => {
        find(filters,{size:10,page:page-1});
      };


    const handlePerRowsChange = async (newPerPage, page) => {
        find(filters,{size:newPerPage,page:page});

      };
    

    return (
        <div>
            <Breadcrumb title={user_title_text}/>
            <br/>
            <div className="row">
                <div className="col">
                    <label>{user_role_filter_text}</label>
                    <select className="form-control digits" id="exampleFormControlSelect23" onChange={(evt)=>{setSelectedRole({id: parseInt(evt.target.value)})}}>
                        <option value={-1}>{dropdown_default_text}</option>
                        {
                            roles.map(role => <option value={role.id}>{role.name}</option>)
                        }
                    </select>
                </div>
                <div className="col">
                    <label>{user_name_filter_text}</label>
                    <input className="form-control" type="text" autocomplete="off" style={{marginLeft: 10}} value={selectedUsername} onChange={(evt)=>{setSelectedUsername(evt.target.value)}} />
                </div>
                <div className="col">
                    <label>{user_email_filter_text}</label>
                    <input className="form-control" type="text" autocomplete="off" style={{marginLeft: 10}} value={selectedEmail} onChange={(evt)=>{setSelectedEmail(evt.target.value)}} />
                </div>
            </div>
            <br/>
            <div style={{display:"flex"}}>
            <div className='btn-right-container'>
                <Button className='btn btn-primary' color="primary" onClick={()=>{setCreateModalIsOpen(true)}}>{add_text}</Button>
            </div>
            </div>
            <DataTable
                pagination 
                paginationServer
                paginationComponentOptions ={{
                    noRowsPerPage:true
                }}
                paginationTotalRows={totalElements} 
                onChangeRowsPerPage={handlePerRowsChange} 
                onChangePage={handlePageChange}
                striped={true}
                center={true}
                columns={columns}
                data={list.map(user => {
                    return {
                      ...user,
                      actions: <div className='flex'>
                                    <IconButton icon={
                                        <span className='action-box large update-btn'>
                                            <i className='icon'>
                                                <i className='icon-pencil'></i>
                                            </i>
                                        </span>
                                        }
                                        text={edit_text}
                                        onClick={()=>{setSelectedUser(user);setEditModalIsOpen(true); }}
                                        color="warning"
                                    />
                                    <IconButton icon={
                                        <span className='action-box large delete-btn' title='Delete Task'>
                                            <i className='icon'>
                                                <i className='icon-trash'></i>
                                            </i>
                                        </span>
                                        }
                                        text={delete_text}
                                        onClick={()=>{handleDelete(user.id)}}
                                        color="danger"
                                    />
                                </div>
                    }
                  })}
                />
            
            
            
            <CreateUserModal 
                isVisible= {createModalIsOpen} 
                onCreate={createUser} 
                onCancel= {()=>{setCreateModalIsOpen(false)}}
                roles={roles}
            />

            <EditUserModal 
                isVisible= {editModalIsOpen} 
                onEdit={editUser} 
                onCancel= {()=>{setEditModalIsOpen(false)}}
                roles={roles}
                oldUser={selectedUser}
            />
            <LoadingSpinner isVisible={loading} />
            <ToastContainer />
            <ConfirmationModal 
                isVisible={isConfirmationModalOpen} 
                description={confirmationModalData.description} 
                onSubmit={confirmationModalData.onSubmit} 
                onCancel={confirmationModalData.onCancel}
                />
        </div>
      
      )
}

export default UsersView;